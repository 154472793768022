<template>
  <button :class="$style.baseBtnIcon" @click.prevent="emitClick">
    <div :class="$style.icon">
      <slot name="icon"></slot>
    </div>
  </button>
</template>

<script>
export default {
  methods: {
    emitClick() {
      this.$emit('click');
    }
  }
};
</script>

<style lang="scss" module>
.baseBtnIcon {
  background: none;
  border: none;
  align-items: center;

  svg path {
    fill: $hoverButton;
  }
}

.baseBtnIcon:focus-visible, .baseBtnIcon:focus{
outline: none;
}
</style>
