var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v("Состояние услуги «Колл-трекинг»")]),_c('br'),(_vm.doubles.length)?_c('base-table',{scopedSlots:_vm._u([{key:"headerText",fn:function(){return [_vm._v(" Список объектов колл-трекинга, доступных для изменения ")]},proxy:true}],null,false,429583476)},[_c('b-table-simple',{attrs:{"hover":"","small":"","caption-top":"","responsive":"","bordered":"","outlined":""}},[_c('b-thead',[_c('b-tr',_vm._l((_vm.table),function(item,index){return _c('b-th',{key:index},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1),_c('b-tbody',_vm._l((_vm.rules),function(number,index){return _c('b-tr',{key:index,class:{ dnone: _vm.doubles[index] === 'dnone' }},[(
              index + 1 < _vm.rules.length &&
                number.CalltrackingNumber === _vm.rules[index + 1].CalltrackingNumber &&
                (index === 0 ||
                  (index - 1 >= 0 &&
                    number.CalltrackingNumber !== _vm.rules[index - 1].CalltrackingNumber))
            )?_c('b-td',{class:{ dnone: _vm.doubles[index] === 'dnone' }},[_c('base-icon-button',{class:{ dnone: _vm.doubles[index + 1] === '' },on:{"click":function($event){return _vm.showDublicates(index)}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('b-icon',{attrs:{"icon":"plus-circle"}})]},proxy:true}],null,true)}),_c('base-icon-button',{class:{ dnone: _vm.doubles[index + 1] === 'dnone' },on:{"click":function($event){return _vm.hideDublicates(index)}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('b-icon',{attrs:{"icon":"dash-circle"}})]},proxy:true}],null,true)})],1):_c('b-td'),_c('b-td',[_vm._v(" "+_vm._s(number.CalltrackingNumber)+" ")]),_c('b-td',[_vm._v(" "+_vm._s(number.Description)+" ")]),_c('b-td',[_vm._v(" "+_vm._s(number.Text)+" ")]),(number.BeginTime !== null)?_c('b-td',[_vm._v(" "+_vm._s(number.BeginTime.replace(/T/, ' '))+" ")]):_c('b-td'),(number.EndTime !== null)?_c('b-td',[_vm._v(" "+_vm._s(number.EndTime.replace(/T/, ' '))+" ")]):_c('b-td'),_c('b-td',[_c('router-link',{attrs:{"to":{
                name: 'CalltrackingRuleEdit',
                query: { id: number.Id }
              }}},[_vm._v(" Редактировать ")])],1)],1)}),1)],1)],1):_c('base-table',{scopedSlots:_vm._u([{key:"headerText",fn:function(){return [_vm._v(" Список объектов колл-трекинга, доступных для изменения ")]},proxy:true}])},[_vm._t("default",[_vm._v("Объекты колл-трекинга, доступные для изменения отсутствуют")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }