<template>
  <div>
    <h2>Состояние услуги «Колл-трекинг»</h2>
    <br />
    <base-table v-if="doubles.length">
      <template #headerText>
        Список объектов колл-трекинга, доступных для изменения
      </template>
      <b-table-simple hover small caption-top responsive bordered outlined>
        <b-thead>
          <b-tr>
            <b-th v-for="(item, index) in table" :key="index">
              {{ item.label }}
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(number, index) in rules"
            :key="index"
            :class="{ dnone: doubles[index] === 'dnone' }"
          >
            <b-td
              v-if="
                index + 1 < rules.length &&
                  number.CalltrackingNumber === rules[index + 1].CalltrackingNumber &&
                  (index === 0 ||
                    (index - 1 >= 0 &&
                      number.CalltrackingNumber !== rules[index - 1].CalltrackingNumber))
              "
              :class="{ dnone: doubles[index] === 'dnone' }"
            >
              <!-- Значок отображения дублей номеров  -->
              <base-icon-button
                @click="showDublicates(index)"
                :class="{ dnone: doubles[index + 1] === '' }"
              >
                <template #icon>
                  <b-icon icon="plus-circle"></b-icon>
                </template>
              </base-icon-button>
              <!-- Значок скрытия дублей номеров  -->
              <base-icon-button
                @click="hideDublicates(index)"
                :class="{ dnone: doubles[index + 1] === 'dnone' }"
              >
                <template #icon>
                  <b-icon icon="dash-circle"></b-icon>
                </template>
              </base-icon-button>
            </b-td>
            <b-td v-else></b-td>
            <b-td>
              {{ number.CalltrackingNumber }}
            </b-td>
            <b-td>
              {{ number.Description }}
            </b-td>
            <b-td>
              {{ number.Text }}
            </b-td>
            <b-td v-if="number.BeginTime !== null">
              {{ number.BeginTime.replace(/T/, ' ') }}
            </b-td>
            <b-td v-else> </b-td>
            <b-td v-if="number.EndTime !== null">
              {{ number.EndTime.replace(/T/, ' ') }}
            </b-td>
            <b-td v-else> </b-td>
            <b-td>
              <router-link
                :to="{
                  name: 'CalltrackingRuleEdit',
                  query: { id: number.Id }
                }"
              >
                Редактировать
              </router-link>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </base-table>
    <base-table v-else>
      <template #headerText> Список объектов колл-трекинга, доступных для изменения </template>
      <slot>Объекты колл-трекинга, доступные для изменения отсутствуют</slot>
    </base-table>
  </div>
</template>

<script>
import BaseTable from '@/components/shared/BaseTable.vue';
import BaseIconButton from '@/components/shared/buttons/BaseIconButton.vue';

export default {
  props: {
    rules: {
      type: Array
    },
    classes: {
      type: Array
    }
  },

  data() {
    return {
      table: [
        { key: 'Mini', label: '' },
        { key: 'CalltrackingNumber', label: 'Номер телефона коллтрекинга' },
        { key: 'Description', label: 'Описание объекта отслеживания' },
        { key: 'Text', label: 'Подробное описание' },
        { key: 'BeginTime', label: 'Дата начала действия' },
        { key: 'EndTime', label: 'Дата окончания действия' },
        { key: 'Edit', label: 'Действие' }
      ],
      doubles: []
    };
  },

  components: {
    BaseTable,
    BaseIconButton
  },

  mounted() {
    this.doubles.push(...this.classes);
  },

  methods: {
    async showDublicates(index, value = '') {
      const showed = await this.findHiddenRows(index);

      // Получает новый массив с новым списком отображаемых строк таблицы
      const test = this.doubles.map((el, classIndex) => {
        if (
          (classIndex + 1 < this.doubles.length && showed[classIndex] && !showed[classIndex + 1])
          || (classIndex + 1 < this.doubles.length && showed[classIndex] && showed[classIndex + 1])
        ) {
          this.doubles[classIndex] = value;
          return this.doubles[classIndex];
        }
        return this.doubles[classIndex];
      });
      this.doubles = test;
    },

    hideDublicates(index) {
      const showed = this.findHiddenRows(index);

      // Получает новый массив с новым списком отображаемых строк таблицы
      const test = this.doubles.map((el, classIndex) => {
        if (classIndex + 1 < this.doubles.length && showed[classIndex] && showed[classIndex + 1]) {
          this.doubles[classIndex + 1] = 'dnone';
          return this.doubles[classIndex];
        }
        return this.doubles[classIndex];
      });
      this.doubles = test;
    },

    findHiddenRows(index) {
      // Проверяет какие строки таблицы показаны, а какие скрыты в rules
      return this.rules.map((el) => el.CalltrackingNumber === this.rules[index].CalltrackingNumber);
    }
  }
};
</script>

<style module>
.hidden {
  display: none;
}
</style>
